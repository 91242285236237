
$(document).on('keyup keypress', 'form', function(e) {
    var keyCode = e.keyCode || e.which;
    if (keyCode === 13) { 
    e.preventDefault();
    return false;
    }
});

    $(document).on('click', '#productType button', function() {
        $('#ddTireType').show();
        $('#productType .glyphicon').css("visibility","hidden");
        $(this).find('.glyphicon').css("visibility","visible");
        // console.log($(this).data('type'));

        if($(this).data('type') == 'sommardack') {
            $('#car-search-box form').attr('action', 'sok/reg/' + $('#tireType').val());
            $('#tireType').on('change', function() {
                
                $('#car-search-box form').attr('action', 'sok/reg/' + $('#tireType').val());
            });
        } else {
            $('#car-search-box form').attr('action', 'sok/reg/' + $(this).data('type'));
        }
        // console.log($('#car-search-box form').attr('action'));
    });

    $(document).on('click', '#selectRims', function() {
        $('#ddTireType').hide();
    });
    
    var SearchActive = 0;
    $(document).on('keyup', '#regNrSearch', function() {
        console.log("changing");
        if($(this).val().length == 6 && SearchActive == 0) {
            console.log("RUNS6");
            SearchActive = 1;

            var url = 'searchRegNr';
            var callOnSuccess = showRegnr;
            var args = { 'regnr' : $(this).val() };

            setTimeout(function(){ }, 1000);
            ajaxRequest(url, callOnSuccess, args);
        }
    });




    $(document).on('keyup', '#search-manuf-text', function(){
        console.log('searching...');
        var valThis = $(this).val().toLowerCase();
        $('#carManufactorers button').each(function(){
            var text = $(this).data('search-item').toLowerCase();
            (text.indexOf(valThis) == 0) ? $(this).show() : $(this).hide();            
        });
    });

    $(document).on('keyup', '#search-model-text', function(){
        console.log('searching...');
        var valThis = $(this).val().toLowerCase();
        $('#carModels button').each(function(){
            var text = $(this).text().toLowerCase();
            (text.indexOf(valThis) == 0) ? $(this).show() : $(this).hide();            
        });
    });

    

    $(document).on('click', '#search-tabs a[href="#search-model-mobile"]', function() {
        $('#search-model-mobile .alert').remove();
        var url = 'getCarManuf';
        var callOnSuccess = listSearchCars;

        ajaxRequest(url, callOnSuccess)
    });

    $(document).on('click', '#carManufactorers button', function(){
        $('#search-model-mobile .alert').remove();
        var url = 'getCarModels';
        var callOnSuccess = listSearchCars;
        var args = { 
            'carBrand' : $(this).val()
        };

        ajaxRequest(url, callOnSuccess, args)
    });

    $(document).on('click', '#carModels button', function(){
        var url = 'searchRegNr';
        var callOnSuccess = showRegnr;
        var args = { 
            'modelid' : $(this).data('modelid'),
            'ktype' : $(this).data('ktype')
        };

        setTimeout(function(){ }, 1000);

        ajaxRequest(url, callOnSuccess, args);
    });


     
    function ajaxRequest(url, callOnSuccess, args) {
        if (args === undefined) {
            args = null;
        }
        $.ajax({
            type: 'get',
            url: url,
            data: args,
            dataType: 'json',
            xhrFields: {
                withCredentials: true
            },
            success: callOnSuccess
        });
    }

    var showRegnr = function (data) {
        $('#search-model-mobile .alert').remove();
        if( data.searchResult !== undefined ) {
            $('#car-search-box form').empty();
            $('#car-search-box form').append(data.searchResult);

            $('#selectComplete .glyphicon').css("visibility","visible");

            $('#search-tabs a[href="#search-reg-mobile"]').tab('show');

            $('#car-search-box form').attr('action', 'sok/reg/kompletta-hjul/falgar'); 
        } else if(data.error) {
            SearchActive = 0;
            $('#search-tabs li:eq(0)').removeClass('active');
            $('#search-tabs li:eq(1)').addClass('active');
            $('#search-reg-mobile').removeClass('active');
            $('#search-model-mobile').addClass('active');
            $('#search-model-mobile').prepend(
                    '<div class="alert alert-danger" style="margin-left:0px;">' +
                        '<p>' + data.error + '</p>' +
                    '</div>'
            );
            var url = 'getCarManuf';
            var callOnSuccess = listSearchCars;

            ajaxRequest(url, callOnSuccess)


        }
    };

  

    var listSearchCars = function(data) {
        $('#search-model-mobile .list-group').remove();
        $('#search-model-mobile .inner-addon').remove();
        $('#search-model-mobile').append(data); 
    }

    $(document).ready(function() {
        $('#loading-image').hide();
    });

    $(document).ajaxStart(function(){
        $('#loading-image').show();
    }).ajaxStop(function(){
        $('#loading-image').hide();
    });

    ////////////////////////////
    // Twitter: @mikedevelops
    ////////////////////////////

    // your custome placeholder goes here!
    var ph = "ABC123",
        searchBar = $('#regNrSearch'),
        // placeholder loop counter
        phCount = 0;

    // function to return random number between
    // with min/max range
    function randDelay(min, max) {
        return Math.floor(Math.random() * (max-min+1)+min);
    }

    // function to print placeholder text in a 
    // 'typing' effect
    function printLetter(string, el) {
        // split string into character seperated array
        var arr = string.split(''),
            input = el,
            // store full placeholder
            origString = string,
            // get current placeholder value
            curPlace = $(input).attr("placeholder"),
            // append next letter to current placeholder
            placeholder = curPlace + arr[phCount];
            
        setTimeout(function(){
            // print placeholder text
            $(input).attr("placeholder", placeholder);
            // increase loop count
            phCount++;
            // run loop until placeholder is fully printed
            if (phCount < arr.length) {
                printLetter(origString, input);
            }
        // use random speed to simulate
        // 'human' typing
        }, randDelay(200, 300));
    }  

    // function to init animation
    function placeholder() {
        $(searchBar).attr("placeholder", "");
        printLetter(ph, searchBar);
    }

    window.setTimeout(placeholder, 1500);
    $('.submit').click(function(e){
        phCount = 0;
        e.preventDefault();
        placeholder();
    });
